.cookies p,
.cookies li,
.cookies ol,
.cookies ul {
  font-size: 12px;
}

.cookies-terms {
  overflow-y: auto;
}
.cookies-terms::-webkit-scrollbar {
  width: 5px;
}

.cookies-bar {
  border-top: 3px solid #6d5ae0;
  background-color: #000;
  opacity: 0.9;
  max-height: 360px;
}
