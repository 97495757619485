.fa-main-bold {
  font-weight: bold;
  color: #6d5ae0;
}

.fa-main {
  color: #6d5ae0;
}

.fa-bold {
  font-weight: bold;
}

.fa-underline {
  text-decoration-line: underline;
}

.fa-ol-p {
  counter-reset: item;
  padding-left: 5px;
}

.fa-ol {
  counter-reset: item;
}

.fa-li {
  display: block;
  text-align: justify;
  padding-top: 4px;
}

.fa-li:before {
  content: counters(item, '.') '.  ';
  counter-increment: item;
  font-weight: bold;
}

.termos p,
.termos li,
.termos ol,
.termos ul {
  font-size: 12px;
  text-align: justify;
}
