body {
    margin: 0;
    font-family: 'M PLUS Rounded 1c', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.cursor-pointer {
    cursor: pointer;
}

/* Font color*/

.font-custom-main {
    color: var(--color-main) !important;
}

.text-blue-light {
    color: var(--color-blue-light) !important;
}

.text-custom-main {
    color: var(--color-main) !important;
}

.text-grey-light {
    color: var(--color-grey-light) !important;
}

.text-grey-lighter {
    color: var(--color-grey-lighter) !important;
}

/* background */

.bg-custom-main {
    background-color: var(--color-main) !important;
}

.bg-blue-light {
    background-color: var(--color-blue-light) !important;
}

.bg-grey-light {
    background-color: var(--color-grey-light) !important;
}

.bg-grey-lighter {
    background-color: var(--color-grey-lighter) !important;
}

.border-custom-main {
    border-color: var(--color-main) !important;
}

.select-w-custom {
    width: 82% !important;
}

.btn-main {
    background-color: var(--color-main-light) !important;
    color: white;
}

.btn-main:hover {
    background-color: var(--color-main) !important;
    color: white;
}

.div-questoes:focus {
    background-color: var(--color-main) !important;
}