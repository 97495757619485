:root {
    /* Colors: */
    --color-main: #6D5AE0;
    --color-main-light: #978bdd;
    --color-blue-light: #0DD5D9;

    --color-grey-light: #9b9b9b;
    --color-grey-lighter: #F1F1F1;

    --animate-duration: 800ms;
    --animate-delay: 0.9s;
}
